export default {
    privacy:
        'Bitte beachten Sie unsere Hinweise zum <a class="hover:underline" href="/de/privacy">Datenschutz</a>.',
    honeypot: 'Bitte lassen Sie dieses Feld leer (Spam-Protection)',
    errors: {
        tooManyRequests:
            'Sie haben zu viele Anfragen gesendet. Bitte versuchen Sie es später erneut.',
        error: 'Es gab einen Fehler. Bitte versuchen Sie es erneut.',
    },
    contact: {
        name: 'Name',
        email: 'Mail',
        concern: 'Ihr Anliegen',
        company: 'Unternehmen',
        firstName: 'Vorname',
        lastName: 'Nachname',
        phone: 'Telefonnummer',
        message: 'Ihre Nachricht',
        send: 'Senden',
        loading: 'Lädt ...',
    },
    configuratorGeneral: {
        network: 'Netzwerk',
        ipConfiguration: 'IP-Konfiguration',
        or: 'oder',
        perMonth: 'pro Monat',
        plusVat: 'zzgl. USt.',
        pricesPlusVat: 'Preise zzgl. USt.',
        price: 'Preis',
        setupAdditional: 'zzgl. { setupFee } Einrichtung (einmalig)',
        phoneNumber: 'Telefonnummer',
        furtherRemarks: 'Weitere Anmerkungen',
        requestHeader: 'Unverbindliche Anfrage',
        requestText:
            'Sie stellen eine unverbindliche Anfrage. Wir setzen uns nach Eingang schnellstmöglich mit Ihnen in Verbindung.',
        requestButton: 'Unverbindlich anfragen',
        mbIncluded: 'Mbit/s inklusive',
        ipv4Addresses: 'IPv4–Adresse | IPv4–Adressen',
        ipv4AddressesIncluded:
            '1 kostenlose IPv4–Adresse inklusive | {count} kostenlose IPv4–Adressen inklusive',
        includedBandwidth: 'Inklusiv–Bandbreite (95 %)',
        includedBandwidthHint:
            'Bei der 95%-Methode werden fünf Prozent der Messpunkte entfernt, um kurzzeitige Traffic-Spitzen nicht zu berechnen.',
        eachMbit: 'Je weiteres Mbit 0,35 €',
        ipv4Subnet: 'IPv4–Subnetz',
        ipv6Subnet: 'IPv6–Subnetz',
        continue: 'Weiter',
        included: 'inklusive',
        alreadyACustomer: 'Sie sind schon Kunde bei uns?',
        loginHere: 'Hier einloggen!',
        notYou: 'Nicht Sie?',
        summaryTitle: 'Ihre Konfiguration',
        showSummary: 'Details anzeigen',
        orderButton: 'Jetzt bestellen',
        orderSuccess: 'Vielen Dank! Wir haben Ihre Bestellung erhalten.',
        exclusivelyForTradespeople:
            'Unsere Angebote richten sich ausschließlich an Gewerbetreibende.',
    },
    configuratorDedicated: {
        base: 'Basis',
        fixed: 'Fest verbaut',
        slotsRemaining:
            'kein Slot verfügbar | Noch {count} Slot verfügbar | Noch {count} Slots verfügbar',
        provision: 'Bereitstellung: 24 Stunden an Werktagen',
        contractTerm: 'Vertragslaufzeit: 1 Monat',
        noticePeriod: 'Kündigunsfrist: 2 Wochen',
        specialDealsAdTitle: 'Nicht das richtige dabei?',
        specialDealsAdDescription:
            'Sie möchten Ihre Infrastruktur aufrüsten und dabei Geld sparen? Werfen Sie einen Blick auf unsere <a class="text-blue-700 underline" href="{href}">kostengünstigen vorkonfigurierten Dedicated Server</a>!',
    },
    configuratorSpecialDeals: {
        base: 'Basis',
        fixed: 'Fest verbaut',
        slotsRemaining:
            'kein Slot verfügbar | Noch {count} Slot verfügbar | Noch {count} Slots verfügbar',
        noticePeriod: 'Kündigunsfrist: 2 Wochen',
        dedicatedAdTitle: 'Nicht das richtige dabei?',
        dedicatedAdDescription:
            'Sie benötigen mehr Flexibilität und möchten Ihren <a class="text-blue-700 underline" href="{hrefDedicated}">Dedicated Server selbst zusammenstellen</a>? Dann besuchen Sie unseren Konfigurator! Für weitere Sonderwünsche steht Ihnen unser Sales-team gerne zur Verfügung. Jetzt <a class="text-blue-700 underline" href="{hrefContact}">Kontakt</a> aufnehmen!',
        noServerSelectedTitle: 'Server ist nicht mehr verfügbar',
        noServerSelectedDescription:
            'Kehren Sie zur Serverauswahl zurück und wählen Sie einen anderen Server aus.',
        noServerSelectedAction: 'Zur Serverauswahl',
    },
    configuratorColocation: {
        forOneDevice: 'Für 1 Device',
        unit: 'HE',
        lockable: 'Abschließbar',
        base: 'Basis',
        power: 'Strom',
        forSingleDevice: 'Für 1 Device',
        powerSupply: 'Stromversorgung 230 V ',
        fuse: 'Absicherung je Feed {fuseProtection}',
        electricalPower: 'Leistungsbereitstellung',
        electricalPowerIncluded: 'kostenlose {count} W inklusive',
        eachFurther: 'je weitere',
        provision: 'Bereitstellung: 48 Stunden an Werktagen',
        contractTerm: 'Vertragslaufzeit: 3 Monate',
        noticePeriod: 'Kündigunsfrist: 1 Monat',
        eachKwh: 'Strompreis je kWh 0,34 €',
        mbsIncluded: 'Inclusive 250 Mbit/s',
        heightUnit: 'Höheneinheit | Höheneinheiten',
    },
    configuratorCloudserver: {
        includedVolume: 'Inklusiv-Volumen',
        provision: 'Bereitstellung: 8 Stunden an Werktagen',
        contractTerm: 'Vertragslaufzeit: 1 Monat',
        noticePeriod: 'Kündigunsfrist: 2 Wochen',
        eachTb: 'Je weiteres TB 5,00 €',
    },
    configuratorS3: {
        pricePerMonth: 'pro GB / je Monat',
        furtherCosts: 'Keine weiteren Kosten!',
        orderHint:
            'Nach dem Absenden der Bestellung erhalten Sie innerhalb von wenigen Minuten die Zugangsdaten zu Ihrem S3 Object Storage.',
    },
    confirmationGeneral: {
        termsOfPayment:
            'Zahlbar innerhalb von 14 Tagen nach Rechnungsstellung per Banküberweisung.',
    },
    menu: {
        customerArea: 'Kundenbereich',
        logout: 'Abmelden',
    },
    imageDescriptions: {
        'ms-a-jpg': 'Foto von einem Büro mit Meetingbereich und Sitzecke.',
        'ms-b-jpg':
            'Bild eines Mitarbeiters, der an seinem Schreibtisch mit einem Macbook sitzt und sein Telefon überprüft.',
        'ms-c-jpg': 'Foto von einem Sitzbereich im Büro vor einem Whiteboard.',
        'ms-d-jpg': 'Foto von einem Mitarbeiter an einem Mac.',
        'ms-e-jpg': 'Foto von einem Mitarbeiter, der etwas auf ein Whiteboard schreibt.',
        'dc-a-jpg':
            'Foto vom Kaltgang in einem Server Cage mit voll besetzten Server Racks auf beiden Seiten.',
        'dc-b-jpg': 'Foto von den Türen zum Server Cage mit dem 23M Logo.',
        'dc-c-jpg':
            'Foto von Kabeln die auf der Rückseite eines Racks in verschiedene Switches führen.',
        'dc-d-jpg': 'Foto von diversen Netzwerkkabeln an Switches.',
        'dc-e-jpg': 'Foto von mehreren Speicher Slots in einem Server.',
        'dc-f-jpg': 'Nahaufnahme von einem Server Netzteil.',
        'data-center-jpg':
            'Drohnenaufnahme von unserem Rechenzentrum mit der Frankfurter Skyline im Hintergrund.',
        'security-jpg': 'Foto von Sicherheitskameras vom Gelände unseres Rechenzentrums.',
        'power-supply-jpg': 'Foto von der Notstromversorgung in unserem Rechenzentrum.',
        'cooling-jpg':
            'Foto von der Klimaanlage auf dem Dach unseres Rechenzentrums mit Grünanlagen im Hintergrund.',
        'fire-protection-jpg':
            'Foto von dem Inergengas für die Löschanlage in unserem Rechenzentrum.',
        'network-jpg': 'Foto von mehreren Netzwerk und Glasfaserkabeln.',
        'ms-jpg': 'Foto von zwei Kollegen die gemeinsam an einem Computer arbeiten.',
        'ms-big-jpg': 'Foto von einem Mitarbeiter, der eine Präsentation über seinen Laptop hält.',
        'ffm-jpg': 'Foto von zwei Kollegen die gemeinsam an einem Server arbeiten.',
        'ffm-big-jpg': 'Foto von einem Mitarbeiter auf dem Boden sitzend vor einem Serverschrank.',
        'techie-jpg':
            'Foto von der Hand einer Person, die grad Festplatten in einem Server austauscht.',
        'dev-jpg': 'Foto von einem Entwickler, der an seinem Code arbeitet.',
        'team-event-jpg': 'Gruppenfoto vom Kollegium bei einer Firmenfeier.',
        'team-party-jpg':
            'Foto von vier Personen, die im Büro eine Party mit Beleuchtung und DJ-Pult veranstalten.',
        'contact-ms-jpg': 'Foto von der Außenfassade von unserem Büro in Münster.',
        'contact-ffm-jpg':
            'Foto von der Außenfassade und dem Sicherheitszaun von unserem Rechenzentrum in Frankfurt.',
    },
    newsletter: {
        subscribe: 'Zum Newsletter anmelden',
        success: 'Vielen Dank! Wir haben Ihnen eine Mail geschickt.',
        error: 'Das hat leider nicht funktioniert. Bitte versuchen Sie es noch einmal.',
    },
    forms: {
        optional: 'Optional',
        customer: {
            first_name: {
                label: 'Vorname',
                placeholder: '',
            },
            last_name: {
                label: 'Nachname',
                placeholder: '',
            },
            email: {
                label: 'Mail',
                placeholder: '',
            },
            company: {
                label: 'Unternehmen',
                placeholder: '',
            },
            country: {
                label: 'Land',
            },
            vat_id: {
                label: 'USt.-Id.',
                placeholder: '',
            },
            has_agreed_terms: {
                label: 'Ich stimme der <a class="hover:underline" href="{privacyUrl}" target="_blank"> Datenschutzrichtlinie </a> und den <a class="hover:underline" href="{termsUrl}" target="_blank">Allgemeinen Geschäftsbedingungen</a> zu.',
            },
        },
        addressDach: {
            street: {
                label: 'Straße',
                placeholder: '',
            },
            number: {
                label: 'Hausnummer',
                placeholder: '',
            },
            country: {
                label: 'Land',
            },
            zip: {
                label: 'PLZ',
                placeholder: '',
            },
            city: {
                label: 'Stadt',
                placeholder: '',
            },
            additional: {
                label: 'Zusatz',
                placeholder: '',
            },
        },
        addressNonDach: {
            street: {
                label: 'Straße & Hausnummer',
                placeholder: '',
            },
            country: {
                label: 'Land',
            },
            city: {
                label: 'PLZ & Stadt',
                placeholder: '',
            },
            additional: {
                label: 'Zusatz',
                placeholder: '',
            },
        },
    },
};
